var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "dark", attrs: { fluid: "", "fill-height": "" } },
    [
      _c(
        "v-layout",
        { attrs: { "align-center": "", "justify-center": "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "", sm6: "", lg3: "", "justify-center": "" } },
            [
              _c("v-img", {
                staticClass: "ma-3",
                attrs: {
                  height: "200px",
                  contain: "",
                  src: require("@/assets/logo.png"),
                  alt: "Zonnekamp",
                },
              }),
              _c(
                "div",
                { staticClass: "pa-4" },
                [
                  _c(
                    "v-form",
                    {
                      ref: "form",
                      attrs: { dark: "" },
                      model: {
                        value: _vm.valid,
                        callback: function ($$v) {
                          _vm.valid = $$v
                        },
                        expression: "valid",
                      },
                    },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Email",
                          rules: _vm.emailRules,
                          solo: "",
                          required: "",
                        },
                        model: {
                          value: _vm.mail,
                          callback: function ($$v) {
                            _vm.mail = $$v
                          },
                          expression: "mail",
                        },
                      }),
                      _c(
                        "v-layout",
                        { staticClass: "right" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                block: "",
                                color: "secondary",
                                "aria-label": "Login",
                              },
                              on: { click: _vm.handleSubmit },
                            },
                            [_vm._v("Vraag Wachtwoord op")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }